<template>
  <span class="userNameColor" :style="{ color: activeColor, fontWeight: fontWeight }">{{ text}}</span>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      activeColor: '#b7227e',
      fontWeight: 600
    }
  },
  props: [
    'text'
    ]
}
</script>
