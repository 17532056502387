<template>
  <div class='bg-color-User'>
    <div class='container-fluid size'>
      <h1 class='text-welcome'>
        Bienvenido de vuelta,
        <text-pink :text='getProfile.name'></text-pink>
        ¿Listo para tu próxima clase?
      </h1>

      <div class='row'>
        <div class='col-sm-12 mt-4 d-flex justify-content-between'>
          <h2 style='color: #b7227e !important'>MI PROGRESO</h2>
          <router-link class='text-decoration-none' to='/user/progreso'>
            <button class='allClasses__container__classes__button rounded'>
              Ver Todos
            </button>
          </router-link>
        </div>
        <template v-if='isLoadingProgressClass'>
          <div class='col-sm-12'>
            <div class='row'>
              <div
                v-for='index in 3'
                :key='index'
                class='col-12 col-sm-6 col-md-6 col-lg-4'
              >
                <skeleton-card-video />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-if='getUserProgressClass && getUserProgressClass.length'
            class='col-sm-12'
          >
            <div class='row'>
              <card-class
                v-for='_class in getUserProgressClass'
                :key='_class.id'
                :data='_class'
                :redirect='`/clase/${_class.slug}`'
                class='col-12 col-sm-6 col-md-6 col-lg-4'
              />
            </div>
          </div>
          <div v-else class='col-sm-12 mt-4 pt-4 pb-4 text-center'>
            <h3>No hay información disponible</h3>
          </div>
        </template>
      </div>

      <div class='row'>
        <div class='col-sm-12 mt-4 d-flex justify-content-between'>
          <h2 style='color: #b7227e !important'>NUEVAS CLASES</h2>
          <router-link class='text-decoration-none' to='/explorar'>
            <button class='allClasses__container__classes__button rounded'>
              Ver Todas
            </button>
          </router-link>
        </div>
        <template v-if='isLoadingClass'>
          <div class='col-sm-12'>
            <div class='row'>
              <div
                v-for='index in 3'
                :key='index'
                class='col-12 col-sm-6 col-md-6 col-lg-4'
              >
                <skeleton-card-video />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-if='getPublicClasses.items && getPublicClasses.items.length'
            class='col-sm-12'
          >
            <div class='row'>
              <card-class
                v-for='_class in getPublicClasses.items'
                :key='_class.id'
                :data='_class'
                class='col-12 col-sm-6 col-md-6 col-lg-4'
              />
            </div>
          </div>

          <div v-else class='col-sm-12 mt-4 pt-4 pb-4'>
            <h3>No hay información disponible</h3>
          </div>
        </template>
      </div>

      <div class='row'>
        <div class='col-sm-12 mt-4 d-flex justify-content-between'>
          <h2 style='color: #b7227e !important'>CLASES POPULARES</h2>
          <router-link style='text-decoration: none' to='/explorar/populares'>
            <button class='allClasses__container__classes__button rounded'>
              Ver Todas
            </button>
          </router-link>
        </div>

        <template v-if='isLoadingPopularClass'>
          <div class='col-sm-12'>
            <div class='row'>
              <div
                v-for='index in 3'
                :key='index'
                class='col-12 col-sm-6 col-md-6 col-lg-4'
              >
                <skeleton-card-video />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if='getPopularClasses.items.length' class='col-sm-12'>
            <div class='row'>
              <card-class
                v-for='_class in getPopularClasses.items'
                :key='_class.id'
                :data='_class'
                class='col-12 col-sm-6 col-md-6 col-lg-4'
              />
            </div>
          </div>

          <div v-else class='col-sm-12 mt-4 pt-4 pb-4'>
            <h3>No hay información disponible</h3>
          </div>
        </template>
      </div>

      <div class='row'>
        <div class='col-sm-12 mt-4 d-flex justify-content-between'>
          <h2 style='color: #b7227e !important'>CLASES EN TENDENCIA</h2>
          <router-link style='text-decoration: none' to='/explorar/tendencia'>
            <button class='allClasses__container__classes__button rounded'>
              Ver Todas
            </button>
          </router-link>
        </div>

        <template v-if='isLoadingTrendClasses'>
          <div class='col-sm-12'>
            <div class='row'>
              <div
                v-for='index in 3'
                :key='index'
                class='col-12 col-sm-6 col-md-4 col-lg-4'
              >
                <skeleton-card-video />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if='getPublicTrendClasses.items.length' class='col-sm-12'>
            <div class='row'>
              <card-class
                v-for='_class in getPublicTrendClasses.items'
                :key='_class.id'
                :data='_class'
                class='col-12 col-sm-6 col-md-6 col-lg-4'
              />
            </div>
          </div>

          <div v-else class='col-sm-12 mt-4 pt-4 pb-4'>
            <h3>No hay información disponible</h3>
          </div>
        </template>
      </div>
      <div class='row'>
        <div class='col-sm-12 mt-4 d-flex justify-content-between'>
          <h2 style='color: #b7227e !important'>CURSOS</h2>
          <router-link style='text-decoration: none' to='/explorar/cursos'>
            <button class='allClasses__container__classes__button rounded'>
              Ver Todas
            </button>
          </router-link>
        </div>
        <template v-if='isLoadingCourses'>
          <div class='col-sm-12 mt-4'>
            <div class='row'>
              <div
                v-for='index in 3'
                :key='index'
                class='col-12 col-sm-6 col-md-6 col-lg-4'
              >
                <skeleton-card-video />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if='getPublicCourses' class='col-sm-12'>
            <div class='row'>
              <card-course
                v-for='course in getPublicCourses.items'
                :key='course.id'
                :data='course'
                class='col-12 col-sm-6 col-md-6 col-lg-4'
              />
            </div>
          </div>

          <div v-else class='col-sm-12 mt-4 pt-4 pb-4'>
            <h3>No hay información disponible</h3>
          </div>
        </template>
      </div>
      <div class='row'>
        <div class='col-sm-12 mt-4 d-flex justify-content-between'>
          <h2 style='color: #b7227e !important'>INSTRUCTORES</h2>
          <router-link class='text-decoration-none' to='/instructores'>
            <button class='allClasses__container__classes__button rounded'>
              Ver Todos
            </button>
          </router-link>
        </div>
        <template v-if='isLoadingInstructors'>
          <div class='col-sm-12 mt-4'>
            <div class='row'>
              <div
                v-for='index in 4'
                :key='index'
                class='col-12 col-sm-6 col-md-6 col-lg-3'
              >
                <skeleton-instructor />
              </div>
            </div>
          </div>
        </template>
        <!-- Display in Desktop -->

        <div class='col-12 mt-3 dashboard-instructors'>
          <carousel
            :navigation-enabled='true'
            :per-page='1'
            :per-page-custom='[
              [423, 2],
              [650, 3],
              [1000, 4],
              [1200, 4],
              [1600, 4],
            ]'
            :speed='500'
            class='instructors-home'
            navigation-next-label="<i class='fas fa-chevron-right'></i>"
            navigation-prev-label="<i class='fas fa-chevron-left'></i>"
          >
            <slide
              v-for='{
                id,
                avatar,
                firstname,
                lastname,
                classes_count,
              } in getPublicInstructorsList.items'
              :key='id'
              class='mt-3 d-flex flex-column flex-card-display flex-carousel-instructors-explore'
              style='flex-basis: 25%'
            >
              <router-link
                :to='`/instructores/instructor/${id}/${firstname.toLowerCase()}-${lastname.toLowerCase()}`'
                style='text-decoration: none'
              >
                <div
                  :style="{
                    backgroundImage: `url('${
                      avatar && avatar.url
                        ? avatar.url
                        : '@/assets/images/logo/default-img-instructors.png'
                    }')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }"
                  class='text-center mx-auto image-instructor-carousel-explore'
                ></div>
                <h4 class='text-uppercase pt-2'>
                  {{ firstname }} {{ lastname }}
                </h4>
              </router-link>
              <p class='font-classes-home'>
                {{ classes_count }} clases
              </p>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TextPink from '../../../components/text/TextPink';
import {
  FETCH_POPULAR_CLASSES,
  FETCH_PUBLIC_CLASSES,
  FETCH_PUBLIC_COURSES,
  FETCH_PUBLIC_INSTRUCTORS_LIST,
  FETCH_TREND_CLASSES,
} from '../../../store/actions/public';
import {
  FETCH_USER_NOTIFICATION,
  DELETE_USER_NOTIFICATION_DASHBOARD,
} from '../../../store/actions/user';
import SkeletonInstructor from '../../Components/skeletons/SkeletonInstructor';
import SkeletonCardVideo from '../../Components/skeletons/SkeletonCardVideo';
import { FETCH_USER_CLASS_PROGRESS } from '../../../store/actions/user';
import CardClass from '../Courses/components/CardClass.vue';
import CardCourse from '../Courses/components/CardCourse.vue';
import Vue from 'vue';
import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'UserDashboard',
  components: {
    TextPink,
    SkeletonCardVideo,
    CardClass,
    CardCourse,
    SkeletonInstructor,
    Carousel,
    Slide,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
  },
  metaInfo () {
    return {
      title: 'Mi Tablero',
    };
  },
  data () {
    return {
      isLoadingInstructors: true,
      isLoadingProgressClass: true,
      isLoadingClass: true,
      isLoadingPopularClass: true,
      isLoadingCourses: true,
      isLoadingTrendClasses: true,
    };
  },
  computed: {
    ...mapGetters([
      'getAllCoursesClassPopular',
      'getPublicInstructors',
      'getPublicFeaturedClasses',
      'getUserProgress',
      'getProfile',
      'getPopularClasses',
      'getUserProgressClass',
      'getPublicClasses',
      'getPublicTrendClasses',
      'getPublicCourses',
      'getPublicInstructorsList',
    ]),
  },
  mounted () {
    this.fetchInstructors();
    this.fetchClassVideos();
    this.fetchPopularClassVideos();
    this.fetchProgressClass();
    this.fetchCourses();
    this.fetchTrendClasses();
    this.fetchNotifications();
  },
  methods: {
    fetchNotifications () {
      this.$store
        .dispatch(FETCH_USER_NOTIFICATION)
        .then((resp) => {
          const { message, id } = resp.data;
          if (message && id) {
            this.notify('success', message);
            this.deleteUserNotificationDashboard(id);
          }
        })
        .catch(() => {
          this.isLoadingCourses = false;
        });
    },
    deleteUserNotificationDashboard (id) {
      this.$store
        .dispatch(DELETE_USER_NOTIFICATION_DASHBOARD, id)
        .then(() => {})
        .catch(() => {});
    },
    fetchCourses (
      filter = {
        per_page: 3,
        order_by: 'DESC',
      },
    ) {
      this.$store
        .dispatch(FETCH_PUBLIC_COURSES, filter)
        .then(() => (this.isLoadingCourses = false))
        .catch(() => {
          this.isLoadingCourses = false;
          this.notify('danger', 'Tenemos problemas para cargar los cursos');
        });
    },
    fetchInstructors (
      filter = {
        order_by: 'ASC_firstname',
        per_page: 5,
      },
    ) {
      this.$store
        .dispatch(FETCH_PUBLIC_INSTRUCTORS_LIST, filter)
        .then(() => (this.isLoadingInstructors = false))
        .catch(() => {
          this.isLoadingInstructors = false;
          this.notify(
            'danger',
            'Tenemos problemas para cargar los instructores',
          );
        });
    },
    notify (type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    fetchClassVideos (
      filter = {
        per_page: 3,
        order_by: 'DESC',
      },
    ) {
      this.$store
        .dispatch(FETCH_PUBLIC_CLASSES, filter)
        .then(() => (this.isLoadingClass = false))
        .catch(() => {
          this.isLoadingClass = false;
          this.notify('danger', 'Tenemos problemas para cargar los clases');
        });
    },
    fetchPopularClassVideos (
      filter = {
        per_page: 3,
        order_by: 'DESC',
      },
    ) {
      this.$store
        .dispatch(FETCH_POPULAR_CLASSES, filter)
        .then(() => (this.isLoadingPopularClass = false))
        .catch(() => {
          this.isLoadingPopularClass = false;
          this.notify('danger', 'Tenemos problemas para cargar los clases');
        });
    },
    fetchProgressClass (
      filter = {
        per_page: 3,
        course_video_current_time: 0,
        is_completed: 0,
      },
    ) {
      this.$store
        .dispatch(FETCH_USER_CLASS_PROGRESS, filter)
        .then(() => (this.isLoadingProgressClass = false))
        .catch(() => {
          this.isLoadingProgressClass = false;
          this.notify(
            'danger',
            'Tenemos problemas para cargar los cursos en clases',
          );
        });
    },
    fetchTrendClasses (
      filter = {
        per_page: 3,
      },
    ) {
      this.$store
        .dispatch(FETCH_TREND_CLASSES, filter)
        .then(() => (this.isLoadingTrendClasses = false))
        .catch(() => {
          this.isLoadingTrendClasses = false;
          this.notify(
            'danger',
            'Tenemos problemas para cargar las clases en tendencia',
          );
        });
    },
    getCourseInstructorCourseVideo (courses = []) {
      if (!courses.length) return 0;

      return courses.reduce((acc, { course }) => {
        if (course) return acc + course.course_videos.length;
        return acc;
      }, 0);
    },
  },
};
</script>

<style>
.subtitle {
  padding: 0;
  color: #b7227e;
  font-size: 13px;
}

.btn {
  -webkit-transition: none !important;
  transition: none !important;
}

.mobile-carousels {
  padding-left: 0px;
}

.font-classes-home {
  color: #b7227e;
  font-size: 13px;
}

.image-instructor-carousel-explore {
  height: 200px;
  padding: 10px;
  width: 200px;
  min-width: 200px;
  justify-content: left;
  margin: 0px !important;
}

@media (min-width: 0px) and (max-width: 767px) {
  .image-instructor-carousel-explore {
    height: 200px;
    padding: 10px;
    width: 100%;
    min-width: 150px;
  }

  .flex-carousel-instructors-explore {
    flex-basis: 50% !important;
    width: auto;
    padding: 5px;
  }

  .dashboard-instructors .VueCarousel-navigation-prev,
  .dashboard-instructors .VueCarousel-navigation-next {
    display: block !important;
  }

  .cards-instructors .VueCarousel-navigation-prev {
    left: 85% !important;
    top: 0 !important;
    z-index: 100;
    background-image: url(/img/theme/arrow-left-black.png);
    background-repeat: no-repeat;
    background-size: 40px;
    width: 40px;
    height: 8px;
  }

  .VueCarousel-navigation-button:focus {
    outline: 1px solid transparent !important;
  }

  .fa-chevron-right:before {
    color: transparent !important;
  }

  .fa-chevron-left:before {
    color: transparent !important;
  }

  .col-sm-12 h3 {
    text-align: center;
  }

  .image-instructor-carousel-explore {
    height: 200px;
    padding: 10px;
    width: 100%;
    min-width: 150px;
  }

  .flex-carousel-instructors-explore {
    flex-basis: 50% !important;
    width: auto;
    padding: 5px;
  }

  .dashboard-instructors .VueCarousel-navigation-prev {
    left: 85% !important;
    top: 0% !important;
    z-index: 100;
    background-image: url(/img/theme/arrow-left-black.png);
    background-repeat: no-repeat;
    background-size: 40px;
    width: 40px;
    height: 8px;
  }

  .dashboard-instructors .VueCarousel-navigation-next {
    background-image: url(/img/theme/arrow-right-black.png);
    background-repeat: no-repeat;
    background-size: 40px;
    width: 40px;
    height: 8px;
    right: 12% !important;
    top: 0 !important;
    z-index: 100;
    position: absolute;
  }
}


</style>
